import React from 'react'
import Image from 'react-bootstrap/Image'



const Home =()=>{

        return(
            <><div id="home">
                <Image src="./pictures/home.jpg" className="responsiveH" fluid />
            </div>
            </>
        )
}

export default Home